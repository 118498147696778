import { Link } from 'gatsby'

import downloadFile from '~/assets/pdfs/How-to-Kickstart-Online-Marketplace-Business-Codica.pdf'
import HeadSection from '~/components/blocks/HeadSection'
import RecentArticles from '~/components/blocks/RecentArticles'
import SocialBlock from '~/components/blocks/SocialBlock'
import MainLayout from '~/components/layouts/MainLayout'
import { AllArticlePageProps } from '~/types/common-props'
import PrimeSectionLeadMagnets from '~/views/EbookLandings/components/PrimeSectionLeadMagnets'

const CustomMarketplaceGuideThanks = ({ data }: AllArticlePageProps) => (
  <MainLayout isTransparent>
    <PrimeSectionLeadMagnets
      title="Thank you! Your download can be found below:"
      description={
        <>
          Looking for more ways to kickstart your marketplace? Schedule
          <Link to="/contacts/" className="mx5" key="keyLink">
            a free 30-minute consultation
          </Link>
          with our CEO.
        </>
      }
      isDownload
      downloadFile={downloadFile}
      downloadId="downloadEbookThanks"
      downloadBtnName="Download eBook"
      maxWidthTitle="750px"
      maxWidthDescription="600px"
    />

    <section style={{ margin: '70px 0 50px' }}>
      <HeadSection
        position="left"
        title="In the meantime, take a look at our most recent articles:"
      />
      <RecentArticles data={data} buttonText="Explore Codica Blog" />
    </section>

    <SocialBlock />
  </MainLayout>
)

export default CustomMarketplaceGuideThanks
